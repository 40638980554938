export const PopularWords = [
  {
    text: "BAKUNE",
  },
  {
    text: "リカバリーウェア",
  },
  {
    text: "MIGARU",
  },
  {
    text: "サンダル",
  },
]
