<template>
  <ClientOnly>
    <div :class="[$style.sidebar, { [$style.is_show]: isShow }]">
      <AtomsConsumerButton :class="$style.close" @click="closeSidebar" />
      <div :class="$style.container">
        <div :class="$style.btn_box">
          <AtomsConsumerLink url="/shop?geolocation=true">
            <StoreLocationButton variant="transparent" block> お近くの店舗を探す </StoreLocationButton>
          </AtomsConsumerLink>
          <AtomsConsumerLink v-if="isShowBrowsingHistory" url="/browsing_history">
            <AtomsConsumerButton variant="transparent" block>閲覧した商品</AtomsConsumerButton>
          </AtomsConsumerLink>
        </div>

        <div v-if="navigations" :class="$style.navigation">
          <template v-for="nav in navigations" :key="nav.document_id">
            <div
              v-if="nav.layout_type === 'link' && (nav.display_type === 'sp' || nav.display_type === 'all')"
              :class="$style.header"
            >
              <AtomsConsumerLink :url="nav.url" @click="closeSidebar">
                <p :class="$style.title">{{ nav.title }}</p>
              </AtomsConsumerLink>
            </div>

            <MoleculesConsumerAccordion
              v-if="nav.layout_type === 'panel' && (nav.display_type === 'sp' || nav.display_type === 'all')"
              icon="plus-minus"
              is-open-by-default
              :class="$style.panel"
            >
              <template #title>
                <AtomsConsumerLink :url="nav.url" :class="$style.title">
                  {{ nav.title }}
                </AtomsConsumerLink>
              </template>
              <template #content>
                <div v-for="subNav in nav.navigations" :key="subNav.document_id" :class="$style.panel_content">
                  <MoleculesConsumerAccordion
                    v-if="
                      subNav.layout_type === 'head' && (subNav.display_type === 'sp' || subNav.display_type === 'all')
                    "
                    icon="plus-minus"
                    is-open-by-default
                    :class="$style.accordion"
                  >
                    <template #title>
                      <AtomsConsumerLink :url="subNav.url" :class="$style.title">
                        {{ subNav.title }}
                      </AtomsConsumerLink>
                    </template>
                    <template #content>
                      <div v-if="subNav.category_urls && subNav.category_urls.length > 0" :class="$style.categories">
                        <div v-for="category in subNav.category_urls" :key="category?.url">
                          <AtomsConsumerLink
                            :class="[$style.link, 'gtm_sidebar_product_btn']"
                            :url="category?.url ?? ''"
                            @click="closeSidebar"
                          >
                            {{ category?.name }}
                          </AtomsConsumerLink>
                        </div>
                      </div>
                    </template>
                  </MoleculesConsumerAccordion>
                  <AtomsConsumerLink
                    v-if="
                      subNav.layout_type === 'link' && (subNav.display_type === 'sp' || subNav.display_type === 'all')
                    "
                    :key="subNav.document_id"
                    :url="subNav.url"
                    :class="$style.sub_navigation"
                    @click="closeSidebar"
                  >
                    {{ subNav.title }}
                  </AtomsConsumerLink>
                </div>
              </template>
            </MoleculesConsumerAccordion>

            <MoleculesConsumerAccordion
              v-if="nav.layout_type === 'head' && (nav.display_type === 'sp' || nav.display_type === 'all')"
              icon="plus-minus"
              is-open-by-default
              :class="$style.head_type"
            >
              <template #title>
                <AtomsConsumerLink :url="nav.url" :class="$style.title">
                  {{ nav.title }}
                </AtomsConsumerLink>
              </template>
              <template #content>
                <div v-if="nav.category_urls && nav.category_urls.length > 0" :class="$style.head_content">
                  <div v-for="category in nav.category_urls" :key="category?.url" :class="$style.category_name">
                    <AtomsConsumerLink
                      :class="[$style.link, 'gtm_sidebar_product_btn']"
                      :url="category?.url ?? ''"
                      @click="closeSidebar"
                    >
                      <AtomsConsumerImage
                        v-if="category.icon_url"
                        :src="category.icon_url"
                        :alt="category.name"
                        :class="$style.category_icon_image"
                        :width="30"
                        :height="30"
                      />
                      <span>{{ category?.name }}</span>
                    </AtomsConsumerLink>
                  </div>
                </div>
              </template>
            </MoleculesConsumerAccordion>
          </template>
        </div>
        <OrganismsSns />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { ApiLayoutOne } from "~/types/server/api/layout"
import { useStore } from "~/stores"

type Props = {
  navigations: ApiLayoutOne["navigation"]
  isShow: boolean
}

const props = withDefaults(defineProps<Props>(), {
  navigations: () => [],
  isShow: false,
})
const emit = defineEmits<{
  close: []
}>()
const closeSidebar = () => emit("close")

const store = useStore()
const route = useRoute()
const isShowBrowsingHistory = computed(() => store.productBrowsingHistory.length > 0)

watch(
  () => props.isShow,
  (value) => {
    if (value) {
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", closeSidebar)
    } else {
      window.removeEventListener("popstate", closeSidebar)
    }
  },
)

watch(
  () => route.fullPath,
  () => {
    closeSidebar()
  },
)
</script>

<style scoped module lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 120;
  width: 87%;
  height: 100vh;
  height: 100dvh;
  background: $primary-10;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  &.is_show {
    transform: translateX(0);
  }

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 15px;
    cursor: pointer;
    &::before,
    &::after {
      position: absolute;
      top: 14px;
      left: 0px;
      width: 32px;
      height: 1px;
      content: "";
      background: $primary;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  .container {
    height: 100vh;
    height: 100dvh;
    padding: 4.5rem 1rem 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .btn_box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;

      a {
        button {
          padding: 0.5rem 1rem;
        }
      }
    }
    .navigation {
      border-radius: 0.5rem;
      background-color: $white;
      .header,
      .accordion,
      .sub_navigation,
      .panel,
      .head_type {
        padding: 1rem;
        border-bottom: 1px solid $primary-20;
        &:last-child {
          border-bottom: none;
        }
        .sub_navigation,
        .categories {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        .panel_content,
        .head_content {
          border-left: 1px solid $primary-20;
          padding: 0.5rem 0;
          &:first-child {
            margin-top: 1rem;
          }
          .accordion {
            padding: 0 0 0 1rem;
          }
        }
        .head_content {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          .category_name {
            padding-left: 0.75rem;
            .link {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: $font-size-12;
              .category_icon_image {
                width: 30px;
                height: 30px;
                object-fit: contain;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid $black-10;
              }
            }
          }
        }
      }
      .title {
        @include main-font;
        color: $primary;
        text-align: left;
        font-weight: bold;
        font-size: $font-size-16;
      }
      .link {
        width: fit-content;
      }
    }
  }

  .sns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2.25rem 1.5rem;
    .x,
    .instagram,
    .facebook {
      height: 36px;
      > img {
        height: 100%;
      }
    }
  }
}
</style>
