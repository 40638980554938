<template>
  <div :class="$style.search_form">
    <div :class="$style.search_wrapper">
      <form
        action="#"
        :class="$style.form"
        autocomplete="off"
        enterkeyhint="search"
        @submit.prevent="search(keywordRef)"
      >
        <AtomsConsumerInput
          ref="searchRef"
          v-model="keywordRef"
          type="search"
          placeholder="検索"
          :class="$style.search_input"
        />
        <AtomsConsumerImage src="/images/icon/primary-search.svg" :class="$style.search_icon" alt="検索" />
      </form>
    </div>
    <div :class="$style.suggestion">
      <div :class="$style.words">
        <p :class="$style.title">人気の検索ワード</p>
        <div :class="$style.word_list">
          <AtomsConsumerButton
            v-for="word in PopularWords"
            :key="word.text"
            :class="$style.word"
            @click="search(word.text)"
          >
            {{ word.text }}
          </AtomsConsumerButton>
        </div>
      </div>
      <div v-show="productCategories" :class="$style.categories">
        <p :class="$style.title">カテゴリー</p>
        <ul :class="$style.category_list">
          <template v-for="category in productCategories" :key="category">
            <li :class="$style.category">
              <AtomsConsumerLink :url="getSiteProductCategoryUrl(category) + `?keyword=${keywordRef}`">
                # {{ category.name }}
              </AtomsConsumerLink>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div :class="$style.close">
      <AtomsConsumerButton @click="closePanel">
        <AtomsConsumerImage src="/images/icon/close-line.svg" :class="$style.searchIcon" alt="閉じる" />
        閉じる
      </AtomsConsumerButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductCategoryFragment } from "~/types/type.generated"
import { getSiteProductCategoryUrl } from "~/utils/functions/product"
import { PopularWords } from "~/const/popular_word"

defineProps<{
  productCategories: ProductCategoryFragment[]
}>()

const searchRef = ref<HTMLInputElement>()
const keywordRef = ref("")
const { navigateToProductsPage } = useProductsPage()
const route = useRoute()

const emit = defineEmits<{
  (e: "onClose"): void
}>()

const closePanel = () => {
  emit("onClose")
}

const resetSearchPanel = (): void => {
  keywordRef.value = ""
  closePanel()
}

const search = async (keyword: string) => {
  keywordRef.value = keyword
  await navigateToProductsPage(keywordRef.value)
}

watch(
  () => route.fullPath,
  () => {
    resetSearchPanel()
  },
)
</script>

<style module scoped lang="scss">
.search_form {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  .search_wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    background: $black-5;
    margin-bottom: 2rem;
    .form {
      position: relative;
      width: 100%;
      .search_icon {
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 1rem;
        height: 1rem;
        transform: translateY(-50%);
      }
      .search_input {
        padding-left: 2.5rem;
      }
    }
  }
  .suggestion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    @include md {
      flex-direction: row;
    }
    .title {
      font-size: $font-size-14;
      color: $primary;
      margin-bottom: 1rem;
    }
    .words {
      @include md {
        width: 40%;
      }
      .word_list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .word {
          font-size: $font-size-14;
          cursor: pointer;
          font-weight: normal;
        }
      }
    }
    .categories {
      @include md {
        width: 60%;
      }
      .category_list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        .category {
          list-style-type: none;
          a {
            font-size: $font-size-14;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .close {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      font-weight: normal;
    }
  }
}
</style>
