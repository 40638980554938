<template>
  <MoleculesConsumerModal
    v-model="localValue"
    max-width="440px"
    :min-width="minScreenMd ? '440px' : '100vw'"
    :min-height="minScreenMd ? '100vh' : '60vh'"
    :max-height="minScreenMd ? '100vh' : '60vh'"
    snap-to-edge="bottom"
    pc-snap-to-edge="right"
  >
    <div :class="$style.add_cart_modal">
      <MoleculesConsumerHeading
        title="カートに商品を追加しました。"
        align="left"
        variant="sub"
        :title-font-size-px="20"
      />

      <div v-for="cartItem of cartItems" :key="cartItem.sku.document_id" :class="$style.product_item">
        <div :class="$style.product_detail">
          <AtomsConsumerImage
            :class="$style.img"
            :src="cartItem.sku.main_image"
            :alt="`${cartItem.product.name} - ${cartItem.sku.name}${
              cartItem.sku.size ? `(${cartItem.sku.size})` : null
            }の画像`"
            :width="72"
            :height="96"
          />
          <div>
            <div :class="$style.product_title">
              <h2>{{ cartItem.product.name }}</h2>
              <p>{{ cartItem.product.material }}</p>
            </div>
            <p v-if="!isEGift" :class="$style.sku_detail">
              <span v-if="cartItem.sku.size"> サイズ: {{ cartItem.sku.size }} </span>
              <span v-if="cartItem.sku.color"> / 色: {{ cartItem.sku.color }} </span>
            </p>
          </div>
        </div>

        <AtomsConsumerButton
          v-if="
            !cartItem.gifts?.length &&
            !cartItem.embroideries?.length &&
            !cartItem.product.is_gift &&
            !cartItem.group_wrappings?.length &&
            !cartItem.group_wrapped_uniq_id
          "
          variant="secondary"
          block
          rounded
          @click="openSelectGift(cartItem.sku.document_id)"
        >
          <AtomsConsumerImage src="/images/icon/gift-wrapping.svg" alt="ギフトラッピング" :width="20" :height="20" />
          ギフトラッピングを設定する
        </AtomsConsumerButton>
      </div>
      <div :class="$style.bottom_button">
        <div :class="$style.buttons">
          <AtomsConsumerLink url="/order/cart" :class="$style.button_link">
            <AtomsConsumerButton variant="secondary" block rounded>カートを見る</AtomsConsumerButton>
          </AtomsConsumerLink>
          <AtomsConsumerLink url="/order/new" :class="$style.button_link">
            <AtomsConsumerButton variant="primary" block rounded>購入手続きへ</AtomsConsumerButton>
          </AtomsConsumerLink>
        </div>
      </div>

      <div v-if="recommendProducts.length > 0">
        <hr />
        <MoleculesConsumerHeading title="あなたへのおすすめ" align="left" variant="sub" :title-font-size-px="20" />
        <OrderRemainAmount />
        <CartRecommendCarousel :recommend-products="recommendProducts" is-vertical />
      </div>

      <div v-if="isShowBrowsingHistory">
        <hr />
        <MoleculesConsumerHeading title="閲覧した商品" align="left" variant="sub" :title-font-size-px="20" />
        <OrderRemainAmount />
        <PagePartsProductBrowsingHistory :class="$style.parts_blank" no-padding is-vertical />
      </div>
    </div>
    <MoleculesConsumerModal v-model="visibleSelectGift" :enable-browser-back-close="false" :class="$style.gift_modal">
      <PagePartsCartPartsSettingGift
        :gift-wrappings="selectGiftCart?.wrapping ?? []"
        :gift-messages="selectGiftCart?.message ?? []"
        :gift-cards="selectGiftCart?.card ?? []"
        :cart-detail="selectGiftCart?.cartDetail"
        :is-card-only="selectGiftCart?.cartDetail.product.is_wrapping"
        @confirm="wrappingConfirm"
        @cancel="cancelGiftModal"
      />
    </MoleculesConsumerModal>
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { CartGift, CartItem } from "@tential/ec-gql-schema/models/order"
import dayjs from "dayjs"
import { useStore } from "~/stores"
import { cloneDeep } from "~/utils/functions/common"
import { SkuFragmentFragment } from "~/types/type.generated"
import { useCart } from "~/composables/useCart"
import { useToast } from "~/state"
import { CartListWithGift } from "~/types/cart"
import type { ApiRecommendSideCartProductsResponse, RecommendProduct } from "~/types/server/api/recommend/side_cart"

const isEGift = computed<boolean>(() => store.eGift.is_select)
const isShowBrowsingHistory = computed(() => store.productBrowsingHistory.length > 0)

const store = useStore()
const { cartState, cartLine, removeCartItem, addCartItem } = useCart()
const { filterIndividualGiftWrappings, filterIndividualMessages, filterIndividualCards, fetchGiftPattern } = useGift()

const { width: windowWidth } = useWindowSize()
// @mixin md と同じ値に設定
const minScreenMd = computed(() => windowWidth.value >= 768)

const localValue = computed({
  get() {
    return cartState.isShowAddCartModal
  },
  set(v) {
    cartState.isShowAddCartModal = v
  },
})

const visibleSelectGift = ref<boolean>(false)
/** ギフト編集中のカートのsku_id */
const selectGiftSkuId = ref<string>("")
/** ギフト編集中のカートがラッピング未設定か */
const isNotWrappingSelectGift = ref<boolean>(false)

const isSendRecommendViewForGA = ref<boolean>(false)

// おすすめ商品
const recommendProducts = ref<RecommendProduct[]>([])
// カート内の商品が変更されたかをチェックするためのカート情報
const latestCartList = ref<CartItem[]>([])
const cartItems = ref<ProductAndSku[]>([])

const updateRecommendProducts = () => {
  latestCartList.value = cloneDeep(store.cartList)

  // カートに商品が入っていない場合は何もしない
  if (store.cartList.length === 0) {
    return
  }

  // 商品閲覧履歴が無い場合は何もしない(商品閲覧履歴はレコメンドAPIに必須なため)
  // 現状商品閲覧履歴が無いのにカートに商品が入ることは無いはずだが、今後もし一覧画面から直接カートに入れるとかできるようになったら考慮が必要かも
  if (store.productBrowsingHistory.length === 0) {
    return
  }

  const localUserId = localStorage.getItem("localUserId") ? (localStorage.getItem("localUserId") as string) : ""
  $fetch<ApiRecommendSideCartProductsResponse>("/api/recommend/side_cart", {
    method: "POST",
    body: {
      local_user_id: localUserId,
      pv_products: store.productBrowsingHistory
        .filter((history) => history.pv_timestamp)
        .map((history) => ({
          product_id: history.document_id,
          pv_timestamp: history.pv_timestamp,
        }))
        .slice(0, 100),
      cart_products: store.cartList
        .filter((cart) => cart.add_to_cart_timestamp)
        .map((cart) => ({
          product_id: cart.product_id,
          add_to_cart_timestamp: cart.add_to_cart_timestamp,
        })),
      local_timestamp: dayjs().unix(), // 秒（ミリ秒ではないことに注意）
    },
  }).then((results) => {
    const products = results?.products ?? []
    recommendProducts.value = products
    // GAの送信(おすすめ商品に変更が無くてもレコメンドAPIをリクエストした場合は送信する)
    isSendRecommendViewForGA.value = false
    gaPushForView()
  })
}

const toast = useToast()

const cartListWithGift = computed(() => {
  const giftPatterns = cartLine.value.map((item: ProductAndSku): CartListWithGift => {
    // ラッピング済み商品・ギフト商品・ラッピング不可商品以外はギフト設定可能とする
    if (item.product.is_gift || item.product.is_wrapping) {
      return {
        wrapping: [],
        message: [],
        card: [],
        cartDetail: item,
      }
    }
    return {
      wrapping: filterIndividualGiftWrappings(item.product.document_id, null, item.sku as SkuFragmentFragment),
      message: filterIndividualMessages(item.product.document_id, item.sku.document_id),
      card: filterIndividualCards(item.product.document_id, item.sku.document_id),
      cartDetail: item,
    }
  })
  return giftPatterns
})

const openSelectGift = async (skuId: string, isNotGift: boolean = false): Promise<void> => {
  selectGiftSkuId.value = skuId
  isNotWrappingSelectGift.value = isNotGift
  await fetchGiftPattern() // 取得回数が多くなる場合は都度取得しないようにする
  visibleSelectGift.value = true
}

const selectGiftCart = computed(() =>
  cartListWithGift.value.find((item) => item.cartDetail.sku.document_id === selectGiftSkuId.value),
)

const wrappingConfirm = async (tempSelectionGift: CartGift, isCard: boolean): Promise<void> => {
  if (!isCard && !tempSelectionGift.sku_id) {
    toast?.showErrorToasted("ラッピングを選択して下さい")
    return
  } else if (isCard && !tempSelectionGift.card_sku_id) {
    toast?.showErrorToasted("カードを選択して下さい")
    return
  }
  const cartItem = cloneDeep(selectGiftCart.value?.cartDetail)
  if (!cartItem) return
  // ラッピング設定付きのカートを追加し、既存のカートを削除
  await addCartItem({
    product_id: cartItem.sku.product_id,
    sku_id: cartItem.sku.document_id,
    site_id: cartItem.sku.site_id,
    is_reservation: cartItem.sku.is_reservation,
    net_stock_quantity: cartItem.sku.net_stock_quantity,
    net_stock_quantity_limit: cartItem.sku.net_stock_quantity_limit,
    gift: {
      sku_id: tempSelectionGift.sku_id,
      message: tempSelectionGift.message,
      product_name: tempSelectionGift.product_name,
      sku_name: tempSelectionGift.sku_name,
      sku_code: tempSelectionGift.sku_code,
      size: tempSelectionGift.size,
      price: tempSelectionGift.price,
      img: tempSelectionGift.img,
      message_img: tempSelectionGift.message_img,
      message_price: tempSelectionGift.message_price,
      message_sku_code: tempSelectionGift.message_sku_code,
      message_sku_id: tempSelectionGift.message_sku_id,
      card: tempSelectionGift.card,
      card_img: tempSelectionGift.card_img,
      card_price: tempSelectionGift.card_price,
      card_sku_code: tempSelectionGift.card_sku_code,
      card_sku_id: tempSelectionGift.card_sku_id,
      product_id: tempSelectionGift.product_id,
    },
  })
  await removeCartItem({
    skuId: cartItem.sku.document_id,
    gift: isNotWrappingSelectGift.value ? undefined : cartItem.gifts?.[0],
  })
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
  toast?.showSuccessToasted(`${isCard ? "メッセージカード" : "ギフトラッピング"}の設定を完了しました`)
}

const cancelGiftModal = (): void => {
  // モーダルを閉じる
  visibleSelectGift.value = false
  selectGiftSkuId.value = ""
}

const { trackRecommendProductView } = useGtagEvent()

const gaPushForView = () => {
  recommendProducts.value.forEach((product) => {
    trackRecommendProductView(product)
  })
}

const getCartItems = (skuIds: string[]) => {
  cartItems.value = []
  skuIds.forEach((skuId) => {
    const item = cartLine.value.find((cartItem) => cartItem.sku.document_id === skuId)
    if (item) cartItems.value.push(item)
  })
}

watch(
  () => cartState.addCartModalSkuIds,
  (skuIds) => {
    if (skuIds.length) {
      updateRecommendProducts()
      getCartItems(skuIds)
    }
  },
  { immediate: true },
)

watch(
  () => store.cartList.length,
  (value: number) => {
    if (value === 0 && store.eGift.is_select) store.resetEGift()
  },
)
</script>

<style lang="scss" scoped module>
.add_cart_modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
  @include md {
    margin-bottom: 0;
  }
  .product_item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    .product_detail {
      display: flex;
      gap: 0.5rem;
    }
    .product_title {
      h2 {
        font-size: $font-size-16;
      }
      p {
        font-size: $font-size-14;
      }
    }
    .sku_detail {
      font-size: $font-size-14;
    }
  }
  .bottom_button {
    position: fixed;
    z-index: 10;
    margin-left: -1rem;
    padding: 1rem;
    background: linear-gradient(#ffffff00, #ffffffaa 20%);
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    @include md {
      margin-left: 0;
      padding: 0;
      position: relative;
      width: 100%;
    }
    .buttons {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      max-width: 440px;
      margin: auto;
      .button_link {
        width: 100%;
      }
    }
  }
}
.gift_modal {
  z-index: 151 !important;
}
</style>
