<template>
  <div id="site-header-id" ref="headerRef" :class="[$style.headerWrapper, { hide: !isShowRef }]">
    <template v-if="headerBanner && !isNotShowBanner">
      <div
        v-if="!headerBanner.image_url && !headerBanner.sp_image_url"
        :style="[{ 'background-color': headerBanner.background_hex ?? '' }]"
        :class="$style.banner"
      >
        <AtomsConsumerLink
          v-if="headerBanner.link_url"
          :url="headerBanner.link_url"
          :class="$style.link"
          :style="[{ color: contrastTextColor(headerBanner.background_hex ?? '') }]"
        >
          {{ headerBanner.title }}
        </AtomsConsumerLink>
      </div>
      <div v-else :style="{ 'background-color': headerBanner.background_hex ?? '' }" :class="$style.banner">
        <AtomsConsumerLink v-if="headerBanner.link_url" :url="headerBanner.link_url">
          <AtomsConsumerImage
            :src="headerBanner.image_url ?? ''"
            :alt="headerBanner.title"
            :class="$style.pcImage"
            :height="25"
            :is-show-placeholder="false"
          />
          <AtomsConsumerImage
            :src="headerBanner.sp_image_url ?? ''"
            :alt="headerBanner.title"
            :class="$style.spImage"
            :height="50"
            :is-show-placeholder="false"
          />
        </AtomsConsumerLink>
        <div v-else>
          <AtomsConsumerImage
            :src="headerBanner.image_url ?? ''"
            :alt="headerBanner.title"
            :class="$style.pcImage"
            :height="25"
            :is-show-placeholder="false"
          />
          <AtomsConsumerImage
            :src="headerBanner.sp_image_url ?? ''"
            :alt="headerBanner.title"
            :class="$style.spImage"
            :height="50"
            :is-show-placeholder="false"
          />
        </div>
      </div>
    </template>
    <header ref="siteHeaderRef" :class="$style.header" @mouseleave="closePanel">
      <div :class="$style.headerContainer">
        <MoleculesConsumerTentialLogoLink :class="$style.tentialLogo" variant="text" :width="114" :height="26" />
        <div v-if="navigations" :class="$style.navigations">
          <template v-for="nav in navigations" :key="nav.document_id">
            <template v-if="nav.display_type === 'pc' || nav.display_type === 'all'">
              <AtomsConsumerLink
                v-if="nav.layout_type === 'link'"
                :url="nav.url"
                :class="$style.navigation"
                @mouseover="closePanel"
              >
                <AtomsConsumerNavigationItem>{{ nav.title }}</AtomsConsumerNavigationItem>
              </AtomsConsumerLink>
              <AtomsConsumerLink v-else-if="nav.layout_type === 'panel'" :url="nav.url" :class="$style.navigation">
                <AtomsConsumerNavigationItem icon="arrow" :class="$style.navigation" @mouseover="openPanel(nav)">
                  {{ nav.title }}
                </AtomsConsumerNavigationItem>
              </AtomsConsumerLink>
            </template>
          </template>
        </div>

        <div
          v-show="openPanelRef"
          :class="$style.panel"
          :style="{ top: `${headerOffsetBottomRef + 1}px` }"
          @mouseleave="closePanel"
        >
          <div :class="$style.container">
            <div :class="$style.lists">
              <template v-for="nav in panelNavigationRef" :key="nav">
                <div
                  v-if="(nav.layout_type === 'head' && nav.display_type === 'pc') || nav.display_type === 'all'"
                  :class="$style.headType"
                >
                  <div :class="$style.head">
                    <AtomsConsumerLink :url="nav.url" :class="$style.title">
                      {{ nav.title }}
                    </AtomsConsumerLink>
                    <div :class="$style.divider"></div>
                  </div>
                  <div
                    v-if="Array.isArray(nav.category_urls)"
                    :class="[$style.list, { [$style.grid]: nav.category_urls.length > 6 }]"
                  >
                    <div v-for="category in nav.category_urls" :key="category.name" :class="$style.category_name_wrap">
                      <AtomsConsumerLink
                        class="gtm_sidebar_product_btn"
                        :class="$style.category_name"
                        :url="category?.url ?? ''"
                        @click="closePanel"
                      >
                        <AtomsConsumerImage
                          v-if="category.icon_url"
                          :src="category.icon_url"
                          :alt="category.name"
                          :class="$style.category_icon_image"
                          :width="30"
                          :height="30"
                        />
                        <span>{{ category?.name }}</span>
                      </AtomsConsumerLink>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          v-show="openSearchPanelRef"
          :class="$style.searchPanel"
          :style="{ top: headerOffsetBottomRef + 'px' }"
          @mouseleave="closePanel"
        >
          <OrganismsSearchForm
            :product-categories="productCategories"
            :is-show="openSearchPanelRef"
            @on-close="closePanel"
          />
        </div>

        <div :class="$style.iconList">
          <AtomsConsumerButton :class="$style.spSearchIcon" @click="onClickSearchSP">
            <AtomsConsumerImage src="/images/icon/primary-search.svg" alt="検索" />
          </AtomsConsumerButton>

          <AtomsConsumerButton :class="$style.searchIcon" @mouseover="openSearchPanel">
            <AtomsConsumerImage src="/images/icon/primary-search.svg" alt="検索" />
          </AtomsConsumerButton>

          <AtomsConsumerLink v-show="navigations" :class="$style.accountIcon" :url="isSigned ? `/mypage` : `/sign_in`">
            <AtomsConsumerImage src="/images/icon/primary-account.svg" alt="マイページ" />
          </AtomsConsumerLink>

          <AtomsConsumerLink
            v-show="navigations"
            :class="$style.accountIcon"
            :url="isSigned ? `/mypage/favorites` : `/sign_in`"
          >
            <AtomsConsumerImage src="/images/icon/fav.svg" alt="お気に入り一覧" />
          </AtomsConsumerLink>

          <AtomsConsumerButton :class="$style.cartIcon" @click="openCart">
            <AtomsConsumerImage src="/images/icon/primary-cart.svg" alt="カート" />
            <span v-if="cartItemNumber > 0" :class="$style.itemNumber">{{ cartItemNumber }}</span>
          </AtomsConsumerButton>

          <AtomsConsumerButton v-show="navigations" :class="$style.hamburgerIcon" @click="onClickShowSpNav">
            <AtomsConsumerImage src="/images/icon/primary-hamburger.svg" alt="メニュー" />
          </AtomsConsumerButton>
        </div>
      </div>
    </header>
  </div>
</template>

<script lang="ts" setup>
import { contrastTextColor } from "@tential/ec-gql-schema/utils/color"
import { useCart } from "~/composables/useCart"
import { useStore } from "~/stores"
import type { ProductCategoryFragment } from "~/types/type.generated"
import { EventHandler } from "~/types/events"
import useCookieState from "~/composables/useCookieState"
import type { ApiLayoutOne } from "~/types/server/api/layout"

type Props = {
  navigations: ApiLayoutOne["navigation"]
  headerBanner?: ApiLayoutOne["banner"]
  productCategories?: ProductCategoryFragment[]
}

withDefaults(defineProps<Props>(), {
  navigations: () => [],
  headerBanner: undefined,
  productCategories: () => [],
})

const emit = defineEmits<{
  (e: "onClickSearchSp"): void
  (e: "onClickShowSpNav"): void
}>()
const onClickSearchSP = () => emit("onClickSearchSp")
const onClickShowSpNav = () => emit("onClickShowSpNav")

const headerRef = ref<HTMLElement>()
const siteHeaderRef = ref<HTMLElement>()
const headerOffsetBottomRef = ref<number>(0)
const route = useRoute()
const router = useRouter()
const store = useStore()
const panelNavigationRef = ref<ApiLayoutOne["navigation"][number]["navigations"]>([])

const { closeAddCartModal } = useCart()
const { getAccessToken } = useCookieState()

const openPanelRef = ref(false)
const openSearchPanelRef = ref(false)
const isShowRef = ref(true)

const isSigned = computed(() => {
  return store.user.email !== "" && getAccessToken()
})

const isNotShowBanner = computed(() => {
  // バナーを表示しないpath
  const notShowPaths = ["/story", "/general-medical-devices"]
  return notShowPaths.includes(route.path)
})

const cartItemNumber = computed(() => {
  if (store.cartList.some((item) => item.group_wrapping)) {
    // まとめてラッピングの場合は、ラッピング対象商品の数を引く
    let num = store.cartList.length
    store.cartList.forEach((item) => {
      if (item.group_wrapping) {
        num -= item.group_wrapping.wrapping_sku_ids.length
      }
    })
    return num
  } else return store.cartList.length
})

const updateHeaderOffsetBottom = (): void => {
  const offsetBottom =
    siteHeaderRef.value &&
    typeof siteHeaderRef.value.offsetTop === "number" &&
    siteHeaderRef.value &&
    typeof siteHeaderRef.value.getBoundingClientRect().height === "number"
      ? siteHeaderRef.value.offsetTop + siteHeaderRef.value.getBoundingClientRect().height
      : 0
  headerOffsetBottomRef.value = offsetBottom
}

const openCart: EventHandler = () => {
  router.push("/order/cart")
}

const openPanel: EventHandler = (_nav: ApiLayoutOne["navigation"][number]) => {
  openSearchPanelRef.value = false
  closeAddCartModal()
  updateHeaderOffsetBottom()
  const navigations = _nav?.navigations ? _nav.navigations : []
  if (navigations.length) panelNavigationRef.value = navigations
  openPanelRef.value = true
}

const openSearchPanel: EventHandler = (_nav: ApiLayoutOne["navigation"][number]) => {
  openPanelRef.value = false
  closeAddCartModal()
  updateHeaderOffsetBottom()
  const navigations = _nav?.navigations ? _nav.navigations : []
  if (navigations.length) panelNavigationRef.value = navigations
  openSearchPanelRef.value = true
}

const closePanel: EventHandler = () => {
  updateHeaderOffsetBottom()
  openPanelRef.value = false
  openSearchPanelRef.value = false
  panelNavigationRef.value = []
}

onMounted(() => {
  updateHeaderOffsetBottom()
  if (siteHeaderRef.value) store.setHeader(siteHeaderRef.value)
})

const { y } = useWindowScroll()
watch(y, (newY, oldY) => {
  // ヘッダーの高さ分スクロール or 上へスクロールした際にヘッダーを表示する
  isShowRef.value = newY <= (headerRef.value?.clientHeight ?? 0) || newY < oldY
})

watch(
  () => route.fullPath,
  () => {
    closePanel()
    openSearchPanelRef.value = false
  },
)
</script>

<style lang="scss" scoped>
.hide {
  transform: translateY(-100%);
}
</style>

<style lang="scss" scoped module>
.headerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  background: rgba($white, 0.9);
  box-shadow: 0px 2px 2px 0px rgba($primary, 0.06);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid $white;
  transition: all 0.4s ease-in-out;
  .panel {
    position: fixed;
    left: 0;
    width: 100%;
    background: $primary-10;
    color: $primary;
    .container {
      max-width: 1308px;
      padding: 3rem;
      margin: 0 auto;
      overflow-y: auto;
      .lists {
        display: flex;
        gap: 3rem;
        .headType {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          .head {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .title {
              @include main-font;
              color: $primary;
              font-size: $font-size-16;
            }
            .divider {
              width: 100%;
              height: 1px;
              background: $black-10;
            }
          }
          .list {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            &.grid {
              display: grid;
              grid-auto-flow: column;
              grid-template-rows: repeat(6, auto);
              grid-template-columns: repeat(3, 1fr);
              gap: 0.75rem 6.25rem;
            }
            .category_name_wrap {
              .category_icon_image {
                width: 30px;
                height: 30px;
                object-fit: contain;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid $black-10;
              }
              .category_name {
                display: flex;
                align-items: center;
                gap: 0.375rem;
                font-size: $font-size-14;
                color: $text-black;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .searchPanel {
    position: fixed;
    left: 0;
    width: 100%;
    padding-top: 4.25rem;
    padding-bottom: 4.5rem;
    background: $primary-10;
  }
  .banner {
    position: relative;
    z-index: 5;
    text-align: center;
    background: $white;
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      cursor: pointer;
      @include lg {
        height: 25px;
      }
      .pcImage {
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
        display: none;
        @include lg {
          display: block;
        }
      }
      .spImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include lg {
          display: none;
        }
      }
    }
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      font-size: $font-size-13;
    }
  }
  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    @include lg {
      height: 80px;
    }
    .headerContainer {
      @include container-lg;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      height: 100%;
      @include lg {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        justify-content: center;
      }
      .navigations {
        align-items: center;
        text-align: center;
        height: 100%;
        gap: 1.25rem;
        margin: auto;
        display: none;
        @include lg {
          display: flex;
        }
        @include xl {
          gap: 1.875rem;
        }
        .navigation {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;

          &:hover {
            &::after {
              position: absolute;
              bottom: -10px;
              left: 0;
              width: 100%;
              height: 2px;
              content: "";
              background: $primary;
              transform: translateY(-50%);
            }
          }
        }
      }
      .spMenu {
        position: relative;
        width: 14px;
        height: 14px;
        @include lg {
          display: none;
        }
      }
      .iconList {
        display: flex;
        gap: 0.25rem;
        @include lg {
          gap: 0.5rem;
        }
        button,
        a {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.5rem;
          display: flex;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          &.isHidden {
            display: none;
          }
        }
        .spSearchIcon {
          @include lg {
            display: none;
          }
        }
        .searchIcon {
          display: none;
          @include lg {
            display: flex;
          }
        }
        .cartIcon {
          position: relative;
          .itemNumber {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 17px;
            height: 17px;
            font-size: $font-size-10;
            font-weight: bold;
            color: $white;
            background-color: $primary;
            border-radius: 50%;
          }
        }
        .hamburgerIcon {
          width: 2.5rem;
          @include lg {
            display: none;
          }
        }
      }
    }
  }
}
</style>
