import {
  IChannelIO,
  BootOption,
  UpdateUserInfo,
  Callback,
  EventProperty,
  FollowUpProfile,
  Appearance,
} from "~/types/channel_talk"

export class ChannelService {
  constructor() {
    this.loadScript()
  }

  loadScript() {
    ;(function () {
      const w = window
      if (w.ChannelIO) return
      const ch: IChannelIO = function () {
        // eslint-disable-next-line prefer-rest-params
        ch.c?.(arguments)
      }
      ch.q = []
      ch.c = function (args) {
        ch.q?.push(args)
      }
      w.ChannelIO = ch
      function l() {
        if (w.ChannelIOInitialized) return

        w.ChannelIOInitialized = true
        const s = document.createElement("script")
        s.type = "text/javascript"
        s.async = true
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js"
        const x = document.getElementsByTagName("script")[0]
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x)
        }
      }
      if (document.readyState === "complete") {
        l()
      } else {
        w.addEventListener("DOMContentLoaded", l)
        w.addEventListener("load", l)
      }
    })()
  }

  boot(option: BootOption, callback?: Callback) {
    window.ChannelIO?.("boot", option, callback)
  }

  shutdown() {
    window.ChannelIO?.("shutdown")
  }

  showMessenger() {
    window.ChannelIO?.("showMessenger")
  }

  hideMessenger() {
    window.ChannelIO?.("hideMessenger")
  }

  openChat(chatId?: string | number, message?: string) {
    window.ChannelIO?.("openChat", chatId, message)
  }

  track(eventName: string, eventProperty?: EventProperty) {
    window.ChannelIO?.("track", eventName, eventProperty)
  }

  onShowMessenger(callback: () => void) {
    window.ChannelIO?.("onShowMessenger", callback)
  }

  onHideMessenger(callback: () => void) {
    window.ChannelIO?.("onHideMessenger", callback)
  }

  onBadgeChanged(callback: (unread: number, alert: number) => void) {
    window.ChannelIO?.("onBadgeChanged", callback)
  }

  onChatCreated(callback: () => void) {
    window.ChannelIO?.("onChatCreated", callback)
  }

  onFollowUpChanged(callback: (profile: FollowUpProfile) => void) {
    window.ChannelIO?.("onFollowUpChanged", callback)
  }

  onUrlClicked(callback: (url: string) => void) {
    window.ChannelIO?.("onUrlClicked", callback)
  }

  clearCallbacks() {
    window.ChannelIO?.("clearCallbacks")
  }

  updateUser(userInfo: UpdateUserInfo, callback?: Callback) {
    window.ChannelIO?.("updateUser", userInfo, callback)
  }

  addTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.("addTags", tags, callback)
  }

  removeTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.("removeTags", tags, callback)
  }

  setPage(page: string) {
    window.ChannelIO?.("setPage", page)
  }

  resetPage() {
    window.ChannelIO?.("resetPage")
  }

  showChannelButton() {
    window.ChannelIO?.("showChannelButton")
  }

  hideChannelButton() {
    window.ChannelIO?.("hideChannelButton")
  }

  setAppearance(appearance: Appearance) {
    window.ChannelIO?.("setAppearance", appearance)
  }
}

export const ignoreChannelTalkPath = [
  "/order",
  "/journals",
  "/law_info",
  "/mypage/edit_email",
  "/story",
  "/return_or_exchange",
  "/gift",
  "/password_forget",
  "/password_edit",
  "/lp",
  "/recoverysandal/recovery-sandal-warm/us",
]

export const instantChannelTalkPath = ["/contact", "/support"]
