<template>
  <div :class="$style.sns_wrap">
    <AtomsConsumerLink url="https://www.facebook.com/tential.inc" :class="$style.link">
      <AtomsConsumerImage src="/images/icon/facebook.svg" alt="facebook" />
    </AtomsConsumerLink>
    <AtomsConsumerLink url="https://twitter.com/TENTIAL_Inc" :class="$style.link">
      <AtomsConsumerImage src="/images/icon/twitter-x.svg" alt="twitter" />
    </AtomsConsumerLink>
    <AtomsConsumerLink url="https://www.instagram.com/tential" :class="$style.link">
      <AtomsConsumerImage src="/images/icon/instagram.svg" alt="instagram" />
    </AtomsConsumerLink>
    <AtomsConsumerLink url="https://page.line.me/510bvevt?openQrModal=true" :class="$style.link">
      <AtomsConsumerImage src="/images/icon/line.svg" alt="line" />
    </AtomsConsumerLink>
  </div>
</template>

<style module scoped lang="scss">
.sns_wrap {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  .link {
    padding: 0.25rem;
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
</style>
